import React from "react"
import PropTypes from "prop-types"
import RichText from "components/elements/richText"
import DetailedSection from "components/blocks/detailedSection"
import CallToAction, { CallToActionProps } from "components/blocks/callToAction"
import "./biosDetails.scss"

const BiosDetails = ({ member, slugPrefix }) => {
  const memberDetails = { ...member, slug: `/${slugPrefix}/${member.slug}` }

  return (
    <div className="Bios">
      <div id="content">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-12 m-auto">
              <div className="bios-wrapper text-center">
                {memberDetails.cta && (
                  <CallToAction
                    className="link headline link-wrapper"
                    ctaWrapper="link-wrapper d-flex justify-content-center mb-2 "
                    data={memberDetails.cta}
                    type="button"
                  />
                )}
                <div className="member-profile mb-5">
                  <DetailedSection {...memberDetails} />
                </div>
                {memberDetails.details && (
                  <RichText richText={memberDetails.details} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

BiosDetails.props = {
  cta: PropTypes.shape(CallToActionProps),
  department: PropTypes.string,
  details: PropTypes.shape({
    json: PropTypes.object,
  }),
  id: PropTypes.string,
  name: PropTypes.string,
  picture: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  role: PropTypes.string,
  slug: PropTypes.string,
}

BiosDetails.propTypes = {
  member: PropTypes.shape(BiosDetails.props),
  slugPrefix: PropTypes.string,
}

export default BiosDetails
