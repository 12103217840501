import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Seo from "components/sections/seo"
import Header from "components/sections/header"
import Footer from "components/sections/footer"
import Template, { BiosTemplateDetailsProps } from "./template"

const TemplateContainer = ({ data: { footer, header, member, template } }) => (
  <>
    <Seo {...member.meta} slug={`${template.slugPrefix}/${member.slug}`} />
    <Header {...header} />
    <Template {...template} member={member} />
    <Footer {...footer} />
  </>
)

TemplateContainer.props = {
  ...BiosTemplateDetailsProps,
  meta: PropTypes.shape(Seo.props),
}

TemplateContainer.propTypes = {
  data: PropTypes.shape({
    footer: PropTypes.shape(Footer.props),
    header: PropTypes.shape(Header.props),
    member: PropTypes.shape(TemplateContainer.props),
    template: PropTypes.shape(Template.props),
  }),
}

export default TemplateContainer

export const query = graphql`
  query StaffMember($slug: String) {
    header: contentfulGlobalHeader(name: { eq: "Header" }) {
      announcement {
        json
      }
      menu {
        menus {
          label
          links {
            text
            path
            nestedLinks {
              text
              path
            }
          }
        }
      }
      utilityLinks {
        path
        text
      }
      cta {
        href: path
        text
      }
    }
    footer: contentfulGlobalFooter(name: { eq: "Footer" }) {
      menu {
        menus {
          label
          links {
            path
            text
          }
        }
      }
      scheduleAppointment {
        path
        text
      }
      socialLinks {
        path
        text
      }
      utilityLinks {
        path
        text
      }
    }
    member: contentfulStaffMember(slug: { eq: $slug }) {
      department
      id
      name
      role
      slug
      cta {
        buttonText
        style
        action {
          __typename
          ... on ContentfulActionRedirect {
            __typename
            openInANewTab
            url
          }
        }
      }
      details {
        json
      }
      picture {
        title
        fluid(maxHeight: 200, maxWidth: 200, quality: 90) {
          src
        }
      }
      meta: seoMetadata {
        canonical
        keywords
        title
        type
        abstract {
          abstract
        }
        description {
          description
        }
        image {
          file {
            url
          }
        }
      }
    }
    template: contentfulWebPageTemplate(model: { eq: "Staff Member" }) {
      slugPrefix
      title
      sections {
        __typename
        ... on ContentfulTemplateSection {
          __typename
          title
        }
      }
    }
  }
`
