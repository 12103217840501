import React from "react"
import PropTypes from "prop-types"
import SECTIONS from "utils/sectionConstants"
import BiosDetails from "views/biosDetails"

const BiosTemplate = ({ member, sections, slugPrefix }) =>
  sections.map((section, i) => {
    switch (section.__typename) {
      case SECTIONS.TemplateDetailsSection:
        return <BiosDetails key={i} member={member} slugPrefix={slugPrefix} />

      default:
        return null
    }
  })

const TemplateSectionProps = {
  __typename: PropTypes.string,
  additionalSettings: PropTypes.shape({
    enableDirectionsCTA: PropTypes.bool,
  }),
  title: PropTypes.string,
}

export const BiosTemplateDetailsProps = BiosDetails.props

BiosTemplate.props = {
  model: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape(TemplateSectionProps)])
  ),
  slugPrefix: PropTypes.string,
  title: PropTypes.string,
}

BiosTemplate.propTypes = {
  ...BiosTemplate.props,
  member: PropTypes.shape(BiosDetails.props),
}

export default BiosTemplate
